<template>
  <div class="grid">
    <div class="col-12">
      <div class="grid">
        <div class="col-12">
          <div class="card">
            <Panel header="How to Send Payment via Zelle on GSMunlock.Tools.">
              <ol>
                <li class="mb-3">
                  <div class="mb-3"><strong>Initiate Payment:</strong></div>
                  <p>- Use Zelle to send your payment to gsmunlock.tools@gmail.com.</p>
                  <p>- Important: Leave the notes section blank.</p>
                </li>
                <li class="mb-3">
                  <div class="mb-3"><strong>Add Credits on Your Account:</strong></div>
                  <p>- Log in to your account on <a href="https://gsmunlock.tools" target="_blank">GSMunlock.Tools</a>.</p>
                  <p>- Click on <strong>"Add Credits"</strong> and then select <strong>"Zelle"</strong>.</p>
                  <p>- Enter the amount you sent through Zelle and provide the confirmation ID from Zelle or your bank statement.</p>
                </li>
                <li class="mb-3">
                  <div class="mb-3"><strong>Confirmation & Support:</strong></div>
                  <p>- If your credits aren't reflected within 5 minutes, reach out to us via the chat feature.</p>
                  <p>- One of our representatives will promptly assist you.</p>
                </li>
              </ol>
              <strong>Important Note:</strong>
              <p>- Make sure you enter the exact amount you sent via Zelle. Incorrect entries may be considered fraudulent.</p>
              <strong>Refund Policy:</strong>
              <p>- If you encounter issues and require a refund, submit a ticket. We'll process it within 24 hours.</p>
            </Panel>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <div class="card">
            <div class="grid">
              <div class="col-4">
                <Panel header="Zelle Payment Request">
                  <form @submit.prevent="handleSubmit(!v$.form.$invalid)" class="p-fluid">
                    <!-- Amount -->
                    <div class="field pb-2 pt-2">
                      <div class="p-float-label">
                        <InputText id="amount" v-model="v$.form.amount.$model"
                                   :class="{'p-invalid':v$.form.amount.$invalid && submitted}"/>
                        <label for="amount" :class="{'p-error':v$.form.amount.$invalid && submitted}">Amount*</label>
                      </div>

                      <span v-if="v$.form.amount.$error && submitted">
                        <span id="email-error" v-for="(error, index) of v$.form.amount.$errors" :key="index">
                          <small class="p-error">{{ error.$message.replace('Value', 'Amount') }}</small>
                        </span>
                      </span>
                    </div>

                    <!-- Transaction Id -->
                    <div class="field pb-2 pt-2">
                      <div class="p-float-label">
                        <InputText id="transactionId" v-model="v$.form.transactionId.$model"
                                   :class="{'p-invalid':v$.form.transactionId.$invalid && submitted}"/>
                        <label for="transactionId" :class="{'p-error':v$.form.transactionId.$invalid && submitted}">Transaction
                          Id*</label>
                      </div>

                      <small
                        v-if="(v$.form.transactionId.$invalid && submitted) || v$.form.transactionId.$pending.$response"
                        class="p-error">{{ v$.form.transactionId.required.$message.replace('Value', 'Transaction Id') }}
                      </small>
                    </div>

                    <p>Min amount: <b>{{ minAmount }} $</b></p>

                    <Button type="submit" label="Submit" class="mt-2"/>
                  </form>
                </Panel>
              </div>
              <div class="col-8">
                <Panel id="zelle-payment-request-table" header="Payment Request">
                  <template #header>
                    <span class="p-panel-title">Zelle Payment Requests</span>
                  </template>
                  <template #icons>
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="search" placeholder="Keyword Search" @input="onSearch" />
                  </span>
                  </template>
                  <ZellePaymentRequestTable/>
                </Panel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, minValue} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {zelle} from "@http/PaymentMethodResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import store from "@store";
import ZellePaymentRequestTable from "@components/zelle/ZellePaymentRequestTable";
import dataTable from "@/mixins/dataTable";

export default {
  setup: () => ({v$: useVuelidate()}),

  mixins: [dataTable],

  components: {
    ZellePaymentRequestTable
  },

  data() {
    return {
      minAmount: null,
      form: {
        amount: null,
        transactionId: null,
      },
      submitted: false,
    }
  },

  validations() {
    return {
      form: {
        amount: {
          required,
          minValue: minValue(this.minAmount),
        },
        transactionId: {required},
      }
    }
  },

  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      zelle.createRequest({
        amount: this.form.amount,
        transaction_id: this.form.transactionId
      })
        .then(() => {
          this.$toast.add(toastParamBuilder.success('Successfully!'));
          this.resetForm();
        })
        .catch((error) => {
          this.$toast.add(toastParamBuilder.error(error.response?.data?.message));
        });
    },

    resetForm() {
      this.form.amount = null;
      this.form.transactionId = null;
      this.submitted = false;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      let form = await zelle.get();
      await store.dispatch('zellePaymentRequest/zellePaymentRequests', to.query)
      await next((vm) => {
        vm.minAmount = form.data.min_amount;
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },

  async beforeRouteUpdate(to) {
    await store.dispatch('zellePaymentRequest/zellePaymentRequests', to.query)
      .finally(() => {
        this.loading = false;
      });
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/demo/badges.scss';
::v-deep(#zelle-payment-request-table .p-panel-content) {
  padding: 0;
}

::v-deep(.p-paginator) {
 .p-paginator-current {
   margin-left: auto;
 }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
